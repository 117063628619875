import { Redirect, Router } from '@reach/router';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import Public from './Public';

import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';

import Home from '../../containers/home';
import ProductDetails from '../../containers/productDetails';
import SearchProduct from '../../containers/searchProduct/SearchProduct';
import Contact from '../../containers/contact';
import AboutUs from '../../containers/about-us';
// import { initGA, logPageView } from '../services/google-analytics';

class Content extends React.PureComponent {
  // componentDidMount() {
  //   initGA();
  //   logPageView(window.location.pathname);
  // }

  render() {
    const projectName = 'Bazar Monjolinho';

    return (
      <>
        <Header />
        <Router>
          <Public
            default path="/home"
            container={<Home />} title={`Home - ${projectName}`}
          />
          <Public
            path="/produto/:id" container={<ProductDetails />}
            title={`Detalhes - ${projectName}`}
          />
          <Public
            path="/busca" container={<SearchProduct />}
            title={`Busca - ${projectName}`}
          />
          <Public
            path="/busca/:id" container={<SearchProduct />}
            title={`Busca - ${projectName}`}
          />
          <Public
            path="/contato" container={<Contact />}
            title={`Contato - ${projectName}`}
          />
          <Public
            path="/sobre-nos" container={<AboutUs />}
            title={`Sobre nós - ${projectName}`}
          />
          <Redirect from={I18n.t('routes.panel.url')} to={I18n.t('routes.login.url')} />
        </Router>
        <a
          href="https://wa.me/5519989711785"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            position: 'fixed',
            bottom: '30px',
            right: '30px',
          }}
        >
          <img
            style={{ width: '80px' }}
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/766px-WhatsApp.svg.png"
            alt="whatsapp"
          />
        </a>
        <Footer />
      </>
    );
  }
}

export default Content;
