import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import AuthRequests from '../../api/auth';
import AuthService from '../../services/auth';
import { addLoading, removeLoading } from './loading';

import * as UserActions from './user';

export const ACTION_AUTH_LOGIN = 'ACTION_AUTH_LOGIN';
export const ACTION_AUTH_LOGOUT = 'ACTION_AUTH_LOGOUT';

export const ACTION_ME = 'ACTION_ME';

export const saveAuthentication = (authData) => ({
  type: ACTION_AUTH_LOGIN,
  auth: authData,
});

export const cleanAuth = () => async (dispatch) => {
  dispatch({
    type: ACTION_AUTH_LOGOUT,
  });
  dispatch(UserActions.cleanUser());
  AuthService.reset();
  AuthService.redirectToLogin();
};

export const authenticate = (userData, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const auth = await AuthRequests.auth(userData);

    AuthService.create(auth);
    dispatch({
      type: ACTION_AUTH_LOGIN,
      auth,
    });

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.login.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const getMe = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const data = await AuthRequests.me();
    dispatch({
      type: ACTION_ME,
      payload: data,
    });
  } catch (err) {
    // message.error(I18n.t(`routes.login.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const logout = () => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanAuth());
  dispatch(removeLoading());
};
