import React, { useState, useEffect, useCallback } from 'react';
import { Pagination } from 'antd';

import { useDispatch } from 'react-redux';

import * as ProductActions from '../../../app/redux/actions/product';

import ProductItem from '../ProductItem';
import Spinner from '../Spinner';

const ProductsList = ({
  title, filter, showPagination = true, limit = 20, emptyMessage,
}) => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  const onPageChange = (page) => {
    setOffset(page === 1 ? 0 : page - 1);
    document.getElementById('list-title').scrollIntoView();
  };

  const getProductDetails = useCallback(async () => {
    setIsLoading(true);
    const response = await dispatch(ProductActions.getProductsPaginated({ ...filter, offset, limit }));
    setProducts(response);
    setIsLoading(false);
  }, [filter, offset]);

  useEffect(() => {
    getProductDetails();
  }, [filter, offset]);

  return (
    <>
      <section id="aa-product" className="mt-3">
        <div className="container">
          {isLoading && !products && <Spinner />}
          <div className="row">
            <div className="col-md-12">
              {!isLoading && products && (
                <div className="row">
                  <div className="aa-product-area">
                    <div className="aa-product-inner">
                      <ul className="nav nav-tabs aa-products-tab">
                        <li className="active">
                          <a
                            href="#all" data-toggle="tab"
                            id="list-title"
                          >
                            {title}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane fade show active" id="all">
                          <ul className="aa-product-catg">
                            {products
                              && products.rows
                              && products.rows.map((product) => (
                                <li key={product.id}>
                                  <ProductItem product={product} />
                                </li>
                              ))}
                          </ul>
                          <div className="text-center">
                            {/* <a
                              className="aa-browse-btn"
                              href="#1"
                            >Veja todos os produtos
                            </a> */}
                            {showPagination && products && products.count > 0 && (
                              <Pagination
                                current={offset + 1}
                                total={products.count}
                                onChange={onPageChange}
                                pageSize={limit}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {(!products || products.count === 0) && !isLoading && emptyMessage && (
                <div className="row">
                  <div className="col-12 text-center">
                    <p>{emptyMessage}</p>
                  </div>
                </div>
              )}
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default ProductsList;
