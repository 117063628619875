import getInstance from './config';

export default class ProductApi {
  static async getProductsPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/product', { params });
    return data;
  }

  static async getProductDetails(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/product/${id}`);
    return data;
  }
}
