/* eslint-disable max-len */
import React from 'react';
import { Link } from '@reach/router';

import { toCurrencyLocale } from '../../../app/utils/currency';

const ProductItem = ({ product }) => (
  <figure>
    <Link className="aa-product-img d-flex justify-content-center" to={`/produto/${product.id}`}>
      <div
        style={{
          width: 250,
          height: 300,
          backgroundImage: `url("${product.str_image_default_url}")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
    </Link>
    <Link className="aa-add-card-btn" to={`/produto/${product.id}`}>
      <span className="fa fa-shopping-cart" />
      Detalhes
    </Link>
    <figcaption>
      <h4 className="aa-product-title">
        <Link to={`/produto/${product.id}`}>{product.name}</Link>
      </h4>
      <span className="aa-product-price">{toCurrencyLocale(product.price)}</span>
    </figcaption>
  </figure>
);

export default ProductItem;
