/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import { useParams } from '@reach/router';
import { useDispatch } from 'react-redux';

import * as ProductActions from '../../app/redux/actions/product';
import { toCurrencyLocale } from '../../app/utils/currency';

import ProductsList from '../../components/shared/ProductsList';
import Spinner from '../../components/shared/Spinner';
import MyReactImageMagnify from '../../components/MyReactImageMagnify/MyReactImageMagnify';

const ProductDetails = (props) => {
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const renderImageMagnify = (item) => (<MyReactImageMagnify {...props} item={item} />);

  const getProductDetails = async () => {
    setIsLoading(true);
    const response = await dispatch(ProductActions.getProductDetails(id));

    const parseObject = {
      title: response.name,
      description: response.description,
      price: toCurrencyLocale(response.price),
      size: response.size,
      images:
        response.photos
        && response.photos.map((photo) => ({
          original: photo.url,
          thumbnail: photo.url,
        })),
    };

    setProduct(parseObject);
    setIsLoading(false);
  };

  useEffect(() => {
    getProductDetails();
  }, [id]);

  return (
    <section className="product-details">
      <div className="container">
        {isLoading && (
        <Spinner />
        )}
        {product && !isLoading && (
          <div className="row mt-5">
            <div className="col-6">
              <ImageGallery
                items={product.images}
                lazyLoad
                showFullscreenButton={false}
                showPlayButton={false}
                renderItem={(item) => renderImageMagnify(item)}
              />
              <div
                id="myPortal" style={{
                  position: 'absolute',
                  top: '0',
                  left: '100%',
                  zIndex: '1000',
                }}
              />
            </div>
            <div className="col-6">
              <h1 className="product-details__title">{product.title}</h1>

              <p className="product-details__size product-details__size__title">Tamanho</p>
              <span className="product-details__size product-details__size__description mb-3">{product.size}</span>
              <p className="product-details__price">{product.price}</p>
              <p className="product-details__size product-details__size__title">Descrição do produto</p>
              <span className="d-block mb-5">{product.description}</span>

              <div className="aa-prod-view-bottom">
                <a
                  href={`https://wa.me/5519989711785?text=Olá, gostaria de saber mais informações sobre o produto: ${product.title} ${window.location.href}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="aa-add-to-cart-btn"
                >
                  Entrar em contato
                </a>
              </div>
            </div>
          </div>
        )}
      </div>

      {product && (
        <ProductsList
          title="Produtos que você também pode gostar"
          filter={{ name: product.title.split(' ')[0] }}
          showPagination={false}
          limit={4}
        />
      )}
    </section>
  );
};

export default ProductDetails;
