/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Link } from '@reach/router';

const Footer = () => (
  <>
    <footer id="aa-footer">
      <div className="aa-footer-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="aa-footer-top-area">
                <div className="row d-flex justify-content-center">
                  <div className="col-md-3 col-sm-6">
                    <div className="aa-footer-widget">
                      <h3>Menu</h3>
                      <ul className="aa-footer-nav">
                        <li><Link to="/home">Início</Link></li>
                        <li><Link to="/sobre-nos">Sobre nós</Link></li>
                        <li><Link to="/contato">Contato</Link></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="aa-footer-widget">
                      <div className="aa-footer-widget">
                        <h3>Entre em contato</h3>
                        <address>
                          <p> Av. Jânio Quadros, 569 - Jardim Bela Vista, Monte Mor - SP, 13190-000</p>
                          <p><span className="fa fa-phone" />(19) 98971-1785</p>
                          <p><span className="fa fa-envelope" />bazarmonjolinho@yahoo.com.br</p>
                        </address>
                        <div className="aa-footer-social">
                          <a
                            href="https://www.facebook.com/bazarmonjolinho/" target="_blank"
                            rel="noopener noreferrer"
                          ><span className="fa fa-facebook" />
                          </a>
                          <a
                            href="https://www.instagram.com/bazarmonjolinho/" target="_blank"
                            rel="noopener noreferrer"
                          ><span className="fa fa-instagram" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
);

export default Footer;
