import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyA2PfG8U8nU6LtnlbQCjWhUgBTAmPRaHIg',
  authDomain: 'monjolinho-726f3.firebaseapp.com',
  databaseURL: 'https://monjolinho-726f3.firebaseio.com',
  projectId: 'monjolinho-726f3',
  storageBucket: 'monjolinho-726f3.appspot.com',
  messagingSenderId: '682190606191',
  appId: '1:682190606191:web:99a40a058111d52845e29a',
  measurementId: 'G-EVB56SK48P',
};

export const firebaseImpl = firebase.initializeApp(firebaseConfig);
export const firebaseDatabase = firebase.database();
