export function replaceAtIndex(str, index, chr) {
  str = str.toString();
  if (index > str.length - 1) return str;
  return str.substr(0, index) + chr + str.substr(index + 1);
}
export function toCurrencyLocale(value) {
  if (!value) return 'R$ 0,00';
  const formattedValue = value && parseFloat(value).toFixed(2);
  const total = replaceAtIndex(formattedValue, formattedValue.length - 3, ',');
  return `R$ ${total}`;
}
