import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_PRODUCTS_PAGINATED,
  ACTION_SAVE_PRODUCT_DETAILS,
} from '../actions/product';

const initialState = Immutable({
  productsPaginated: null,
  productDetails: null,
});

export default function product(
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_PRODUCTS_PAGINATED:
      state = {
        ...state,
        productsPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_PRODUCT_DETAILS:
      state = {
        ...state,
        productDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getProductsPaginated(state) {
  return state.product.productsPaginated;
}

export function getProductDetails(state) {
  return state.product.productDetails;
}
