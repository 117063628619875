import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation, useParams } from '@reach/router';
import { useSelector } from 'react-redux';

import ProductsList from '../../components/shared/ProductsList/ProductsList';

const SearchProduct = () => {
  const { search } = useLocation();
  const { id } = useParams();
  const selectedCategory = useSelector((state) => state.category && state.category.categoryDetails);
  const values = queryString.parse(search);
  const [query, setQuery] = useState(null);

  useEffect(() => {
    if (values && values.q) setQuery(values.q);
  }, [values, id]);

  const getCategory = () => selectedCategory && selectedCategory.name;

  return (
    <section className="product-details">
      {(query || id) && (
      <ProductsList
        title={id ? `Produtos da categoria: ${getCategory()}` : `Resultado da busca por: "${query}"`}
        filter={{
          name: query,
          ...(id && { categoryId: id }),
        }}
        emptyMessage="Nenhum produto foi encontrado com este termo, por favor tente novamente."
      />
      )}
    </section>
  );
};

export default SearchProduct;
