import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_CATEGORIES_PAGINATED,
  ACTION_SAVE_CATEGORY_DETAILS,
} from '../actions/category';

const initialState = Immutable({
  categoriesPaginated: null,
  categoryDetails: null,
});

export default function category(
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_CATEGORIES_PAGINATED:
      state = {
        ...state,
        categoriesPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_CATEGORY_DETAILS:
      state = {
        ...state,
        categoryDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getUsersPaginated(state) {
  return state.category.categoriesPaginated;
}

export function getUserDetails(state) {
  return state.category.categoryDetails;
}
