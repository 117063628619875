import getInstance from './config';

export default class CategoryApi {
  static async getCategoriesPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/category', { params });
    return data;
  }

  static async getCategoryDetails(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/category/${id}`);
    return data;
  }

  static async update(id, userData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/category/${id}`, userData);
    return data;
  }

  static async create(userData) {
    const instance = await getInstance();
    const { data } = await instance.post('/category', userData);
    return data;
  }

  static async remove(id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/category/${id}`);
    return data;
  }

  static async getAllCategoriesPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/category/list', { params });
    return data;
  }
}
