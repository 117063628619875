import React from 'react';

import ReactImageMagnify from 'react-image-magnify';


const MyReactImageMagnify = ({ item }) => (
  <ReactImageMagnify
    {...{
      smallImage: {
        isFluidWidth: true,
        src: item.thumbnail,
      },
      largeImage: {
        src: item.original,
        width: 1200,
        height: 1800,
      },
      enlargedImagePortalId: 'myPortal',
    }}
  />
);

export default MyReactImageMagnify;
