import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import CategoryRequests from '../../api/category';

export const ACTION_SAVE_CATEGORIES_PAGINATED = 'ACTION_SAVE_CATEGORIES_PAGINATED';
export const ACTION_SAVE_CATEGORY_DETAILS = 'ACTION_SAVE_CATEGORY_DETAILS';

export const ACTION_CLEAN_CATEGORY = 'CLEAN_CATEGORY';

export const cleanCategory = () => ({
  type: ACTION_CLEAN_CATEGORY,
  category: null,
});

export const cleanCategoryDetails = () => ({
  type: ACTION_SAVE_CATEGORY_DETAILS,
  category: null,
});

export const getCategories = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  try {
    dispatch({
      type: ACTION_SAVE_CATEGORIES_PAGINATED,
      payload: null,
    });
    const categoriesPaginated = await CategoryRequests.getCategoriesPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_CATEGORIES_PAGINATED,
      payload: categoriesPaginated,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.categories.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getAllCategories = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  try {
    dispatch({
      type: ACTION_SAVE_CATEGORIES_PAGINATED,
      payload: null,
    });
    const categoriesPaginated = await CategoryRequests.getAllCategoriesPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_CATEGORIES_PAGINATED,
      payload: categoriesPaginated,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.categories.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getCategoryDetails = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let data = null;
    dispatch({
      type: ACTION_SAVE_CATEGORY_DETAILS,
      payload: null,
    });
    if (id) {
      data = await CategoryRequests.getCategoryDetails(id);
    }
    dispatch({
      type: ACTION_SAVE_CATEGORY_DETAILS,
      payload: data,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.categories.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const updateCategory = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await CategoryRequests.update(id, data);
    dispatch(getCategories());
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('routes.panel.categories.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const createCategory = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await CategoryRequests.create(data);
    dispatch(getCategories());
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err && err.message === 'category_already_used') {
      message.error('Categoria já existente, por favor utilize outro nome');
    } else {
      message.error(I18n.t('routes.panel.categories.errors.generic'));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const removeCategory = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await CategoryRequests.remove(id);
    dispatch(getCategories());

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('routes.panel.categories.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};
