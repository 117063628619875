import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { MdPhone } from 'react-icons/md';
import { AiOutlineMenu } from 'react-icons/ai';
import { Link, navigate } from '@reach/router';
import { useDispatch } from 'react-redux';

import CategoryRequests from '../../../app/api/category';
import { CategoryActions } from '../../../app/redux/actions';

const Header = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    const response = await CategoryRequests.getCategoriesPaginated();
    setCategories(response);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const setSelectedCategory = (category) => {
    dispatch({ type: CategoryActions.ACTION_SAVE_CATEGORY_DETAILS, payload: category });
  };

  const onSearch = (e) => {
    e.preventDefault();
    navigate(`/busca?q=${search}`);
  };

  return (
    <>
      <header id="aa-header">
        <div className="aa-header-top h-auto d-inline-block">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="aa-header-top-area">
                  <div className="aa-header-top-left">
                    <div className="cellphone hidden-xs">
                      <p>
                        <MdPhone />
                        (19) 98971-1785
                      </p>
                    </div>
                  </div>

                  <div className="aa-header-top-right">
                    <ul className="aa-head-top-nav-right">
                      <li className="">
                        <Link to="/sobre-nos">Sobre nós</Link>
                      </li>
                      <li className="">
                        <Link to="/contato">Contato</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="aa-header-bottom d-none d-md-block">
          <div className="row">
            <div className="col-md-12">
              <div className="aa-header-bottom-area">
                <div className="aa-logo">
                  <Link to="/home">
                    <img
                      alt="logo" className="mr-1"
                      src="/assets/img/logo.jpg"
                    />
                    <p className="titlezao">
                      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                      <strong>BazarMonjolinho</strong> <span>🔍 precisou achou!</span>
                    </p>
                  </Link>
                </div>

                <div className="aa-search-box">
                  <form onSubmit={(e) => onSearch(e)}>
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="O que você está procurando?"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button aria-label="search box" type="submit">
                      <FaSearch />
                    </button>
                  </form>
                </div>

                <div className="aa-warn-sell-online">
                  <h5>Atualmente não possuimos plataforma de venda online!</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="aa-header-bottom-mobile d-md-none">
          <div className="row">
            <div className="col-md-12">
              <div className="aa-header-bottom-area">
                <div className="aa-logo">
                  <Link to="/home">
                    <img
                      alt="logo" className="mr-1"
                      src="/assets/img/logo.jpg"
                    />
                    <p className="titlezao">
                      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                      <strong>BazarMonjolinho</strong> <span>🔍 precisou achou!</span>
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="aa-header-bottom-area">
                <div className="aa-search-box">
                  <form onSubmit={(e) => onSearch(e)}>
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="O que você está procurando?"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button aria-label="search box" type="submit">
                      <FaSearch />
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="aa-header-bottom-area">
                <div className="aa-warn-sell-online">
                  <span>Atualmente não possuimos plataforma de venda online!</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <!-- menu --> */}
      <section id="menu">
        <div className="container">
          <div className="menu-area">
            <nav className="navbar-expand-sm navbar navbar-default d-flex justify-content-start" role="navigation">
              <div className="navbar-header">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  aria-controls="navbarNavAltMarkup"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <AiOutlineMenu style={{ color: '#fff' }} />
                </button>
              </div>
              <div className="collapse navbar-collapse menu">
                <ul className="nav navbar-nav">
                  {categories.map((category, index) => (
                    <li className={`nav-item ${!category.children ? 'link' : ''}`} key={index.toString()}>
                      <Link
                        className="nav-link"
                        to={`/busca/${category.id}`}
                        onClick={() => setSelectedCategory(category)}
                      >
                        {category.name}
                      </Link>

                      {category.children && (
                        <ul>
                          {category.children.map((children) => (
                            <li className={`nav-item ${!children.children ? 'link' : ''}`} key={children.id}>
                              <Link
                                className="nav-link"
                                to={`/busca/${children.id}`}
                                onClick={() => setSelectedCategory(children)}
                              >
                                {children.name}
                              </Link>

                              {children.children && (
                                <ul>
                                  {children.children.map((secChildren) => (
                                    <li className="nav-item link" key={secChildren.id}>
                                      <Link
                                        className="nav-link"
                                        to={`/busca/${secChildren.id}`}
                                        onClick={() => setSelectedCategory(secChildren)}
                                      >
                                        {secChildren.name}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
