import React from 'react';
import './Spinner.less';

const Spinner = () => (
  <div className="row mt-5">
    <div className="col-12 text-center">

      <div
        className="spinner-border text-danger"
        role="status"
      >
        <span className="sr-only">Carregando...</span>
      </div>
    </div>
  </div>
);

export default Spinner;
