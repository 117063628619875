import "./styles/less/index.less";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import moment from "moment-timezone";
import TagManager from "react-gtm-module";
import jstz from "jstimezonedetect";
import { loadTranslations, setLocale, syncTranslationWithStore } from "react-redux-i18n";

import Config from "./app/config/app.config";

import AuthServices from "./app/services/auth";
import i18nDictionary from "./i18n";
import * as AuthActions from "./app/redux/actions/auth";
import initalizeRedux from "./app/redux";
import Routes from "./app/routes";

const redux = initalizeRedux();

export function axiosHandleUnatorizedErr() {
  redux.dispatch(AuthActions.cleanAuth());
}

async function authenticated(store) {
  if (AuthServices.isAuthenticated()) {
    const auth = AuthServices.get();
    await store.dispatch(AuthActions.saveAuthentication(auth));
  }
}

const tagManagerArgs = {
  gtmId: 'GTM-WCNJJFF',
};

TagManager.initialize(tagManagerArgs);

try {
  const tz = jstz.determine();
  const timezone = tz.name() || Config.timezone;
  let { language } = Config.language;
  if (!i18nDictionary[language]) ({ language } = Config);

  // initialize react-redux-i18n
  syncTranslationWithStore(redux);
  redux.dispatch(loadTranslations(i18nDictionary));
  redux.dispatch(setLocale(language));

  moment.tz.setDefault(timezone);
  moment.locale(language);

  authenticated(redux);
} catch (err) {
  // Redirect to error page
}

ReactDOM.render(
  <Provider store={redux}>
    <Routes />
  </Provider>,
  document.getElementById("root")
);
