import React from 'react';
import RotativeBanner from '../../components/shared/RotativeBanner-v2';
import ProductsList from '../../components/shared/ProductsList';

const Home = () => (
  <>
    <div style={{ paddingTop: '236px', paddingBottom: '40px' }}>
      <RotativeBanner />
      <ProductsList
        title="Confira nossos produtos"
        filter={{ limit: 20 }}
        emptyMessage="Nenhum produto foi encontrado, por favor tente novamente."
      />
    </div>
  </>
);

export default Home;
