/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

import Carousel from 'react-elastic-carousel';
import { firebaseDatabase } from '../../../app/config/firebase.config';
import Spinner from '../Spinner/Spinner';

const breakPoints = [{ itemsToShow: 1 }];

const RotativeBanner = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [banners, setBanners] = useState(null);
  useEffect(() => {
    firebaseDatabase
      .ref('banners')
      .once('value')
      .then((snapshot) => {
        setBanners(snapshot.val().filter((i) => i));
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  return (
    <>
      {banners && !isLoading ? (
        <div className="slider-container">
          <Carousel breakPoints={breakPoints}>
            {banners.map((item, index) => (
              <img
                className="d-block" key={index.toString()}
                src={item && item.url} alt="slider"
              />
            ))}
          </Carousel>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default RotativeBanner;
