import React, { useState } from 'react';
import ContactRequests from '../../app/api/contact';

const Contact = () => {
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [from, setFrom] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const onFormSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (!name || !from || !subject || !message) {
      setError('Por favor preencha todos os campos!');
    } else {
      try {
        await ContactRequests.createContact({
          name, from, subject, message,
        });
      } catch (err) {
        setIsSent(true);
        // setError('Ocorreu um erro por favor tente novamente');
      }
    }
    setIsLoading(false);
  };

  return (
    <section className="product-details" style={{ paddingTop: '236px', paddingBottom: '40px' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mt-3">
            <div className="aa-contact-top">
              <h2>Contato</h2>
              <p>Estamos ansiosos para receber o seu contato</p>
            </div>
            <div className="aa-contact-map">
              <iframe
                // eslint-disable-next-line max-len
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Av.%20J%C3%A2nio%20Quadros,%20569%20-%20Jardim%20Bela%20Vista,%20Monte%20Mor%20-%20SP,%2013190-000+(Bazar%20Monjolinho)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
                title="map"
              />
            </div>
            <div className="aa-contact-address">
              <div className="row mt-3">
                <div className="col-md-8">
                  <div className="aa-contact-address-left">
                    <form className="comments-form contact-form" onSubmit={(e) => onFormSubmit(e)}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Seu nome"
                              className="form-control"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="email"
                              placeholder="Seu e-mail"
                              className="form-control"
                              value={from}
                              onChange={(e) => setFrom(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Assunto"
                              className="form-control"
                              value={subject}
                              onChange={(e) => setSubject(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <textarea
                          className="form-control"
                          rows="3"
                          placeholder="Message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </div>
                      <button
                        className="aa-secondary-btn" type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? 'Carregando' : 'Enviar'}
                      </button>
                      {isSent && (
                        <div className="alert alert-success mt-3" role="alert">
                          E-mail enviado com sucesso! em breve entraremos em contato, obrigado.
                        </div>
                      )}
                      {error && !isSent && (
                        <div className="alert alert-danger mt-3" role="alert">
                          {error}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="aa-contact-address-right" style={{ textAlign: 'left' }}>
                    <address>
                      <h4>Bazar Monjolinho</h4>
                      <p>Procurou achou!</p>
                      <p>Av. Jânio Quadros, 569 - Jardim Bela Vista, Monte Mor - SP, 13190-000</p>
                      <a
                        href="https://wa.me/5519989711785"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-block"
                      >
                        <span className="fa fa-whatsapp" />&nbsp;
                        (19) 98971-1785
                      </a>
                      <span
                        className="d-block"
                      >
                        <span className="fa fa-phone" />&nbsp;
                        (19) 3217-7901
                      </span>
                      <p>
                        <span className="fa fa-envelope" />&nbsp;
                        bazarmonjolinho@yahoo.com.br
                      </p>
                      {/* <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D5519989711785%26fbclid%3DIwAR2sppzpgJd8rtY4yezMtGbab_dK4vVrlHsY9i7IniT2IRotqg3v8HSu2aU&h=AT2PEcxnBHrpxoaXcm08jgq-07Mk0CiDLRiLKZmLar6hCiaMghnmBkbLw9RteZJpgFl6chuC4hEuRe-_IU75lOMvXxuv8IDjCLFkmW09IYl6WAE-2RPZGUP0eRHGgTk0MPgik1Vb195arRQ7Kyv-lg">
                        Whatsapp
                      </a> */}
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
