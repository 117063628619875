import Axios from 'axios';
import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import AuthServices from '../services/auth';
import ApiUtil, { handleAxiosError } from '../utils/api';
import { axiosHandleUnatorizedErr } from '../../index';

function getInstance(customContentType = null) {
  const auth = AuthServices.get();

  const axiosInstance = Axios.create({
    baseURL: ApiUtil.getBaseUrl(),
    timeout: 35000,
    headers: {
      'Content-Type': customContentType || 'application/json',
      Authorization: auth && auth.token ? `Bearer ${auth.token}` : undefined,
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (err) => {
      if (err.response.status === 401) {
        message.error(I18n.t('routes.login.errors.user_invalid_token'));
        axiosHandleUnatorizedErr();
      } else if (err.response.status === 403) {
        message.error(I18n.t('routes.login.errors.user_no_access'));
        axiosHandleUnatorizedErr();
      }
      return Promise.reject(handleAxiosError(err));
    },
  );

  return axiosInstance;
}

export default getInstance;
