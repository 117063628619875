import React from 'react';

const Contact = () => (
  <section className="product-details" style={{ paddingTop: '236px', paddingBottom: '40px' }}>
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center mt-3">
          <div className="aa-contact-top">
            <h2>Sobre nós</h2>
            <p>Missão da Empresa Estar presente no dia-a-dia das nossas clientes,
              oferecendo produtos diferenciados, conforto e praticidade, proporcionando soluções diversas com compromisso, praticando sempre respeito com nossas clientes e funcionários.
              Visão Ser referência no ramo e reconhecida como uma empresa que transmite confiança. Atender as necessidades e superar as expectativas de nossos clientes,
              prestando um atendimento personalizado através de uma equipe motivada e comprometida. Valores Respeito. Competência e superação. Integridade. Seriedade. Honestidade.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Contact;
